.card-grid-container {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  animation-name: fadeInto;
  animation-duration: 2s;
}

h2 {
  color: #ecf0f1;
  text-align: center;
  text-shadow: 3px 3px 3px black;
  animation: fadeInto 2s;
}

.br {
  display: block;
  margin-bottom: 5em;
}

@keyframes fadeInto {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
