.flip-card {
  display: flex;
  background-color: transparent;
  width: 300px;
  height: 400px;
  perspective: 1000px;
  margin: 20px;
  padding: 0px;
}

img {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  font-size: 20px;
  text-align: left;
  background-color: #ffffff;
  color: black;
  transform: rotateY(180deg);
}

.flip-card-back > h1 {
  text-align: center;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 30px;
}

.flip-card-back > p {
  padding-left: 20px;
  margin-bottom: 5px;
}
