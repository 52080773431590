.showcaseButtonContainer {
  text-align: center;
}

a {
  color: black;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #7ea8df;
  font-family: Arial, Helvetica, sans-serif;
}

.showcase {
  color: white;
  margin-top: 200px;
  margin-bottom: 10px;
  text-align: center;
  animation-duration: 1.6s;
  animation-name: fadeIn;
}

.showcaseButton {
  padding: 10px 20px;
  margin: 5px;
  font-size: 20px;
  background-color: #b9b9b9;
  border: 3px solid black;
  border-width: 3px;
  border-bottom-color: black;
  border-top-color: white;
  border-left-color: white;
  border-right-color: black;
  text-decoration: none;
  display: inline-block;
  animation-duration: 2s;
  animation-name: fadeIn;
}

.showcaseButton:hover {
  transform: translate(-3px, -3px);
  background-color: #bebebe;
  box-shadow: 5px 5px 5px black;
  transition-duration: 0.1s;
}

.nameShowcase {
  font-family: 'Lato', sans-serif;
  font-size: 60px;
  text-shadow: 3px 3px 5px black;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(75%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
